import React from 'react'
import DarkButton from '../../components/buttons/DarkButton/dark-button'
import './blog-post-rich-text.scss'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// import { Link } from 'gatsby'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <em>{children}</em>
const Heading3 = ({ children }) => <h3>{children}</h3>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Text>{children}</Text>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <Heading3>{children}</Heading3>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a className="inline-hyperlink" href={node.data.uri}>{children}</a>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        // <img src={node.data.target.fixed.src} alt={node.data.target.title}/>
        <GatsbyImage image={getImage(node.data.target)} alt={node.data.target.title} />
      )
    },
    [BLOCKS.TABLE]: (node, children) => (
      <table>
        <tbody>{children}</tbody>
      </table>
    ),
    [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
    [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
  },
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
}

const BlogPostRichText = (props) => {
  return (
    <section className="post-content-section">

      {props.richText && renderRichText(props.richText, options)}
      {/* {props.richText && documentToReactComponents(JSON.parse(props.richText.raw), options)} */}
      {props.hasBlogEndCta ?
        <div className='blog-page-end-cta-section'>
          <div>
            {props.endOfPageButtonLinkOne && props.endOfPageButtonLinkOne?.linkUrlAddress &&
              <DarkButton
                href={props.endOfPageButtonLinkOne?.linkUrlAddress}
                buttonText={props.endOfPageButtonTitleOne}
              ></DarkButton>
            }
          </div>
          <div>
            {props.endOfPageButtonLinkTwo && props.endOfPageButtonLinkTwo?.linkUrlAddress &&
              <DarkButton
                href={props.endOfPageButtonLinkTwo?.linkUrlAddress}
                buttonText={props.endOfPageButtonTitleTwo}
              ></DarkButton>
            }

          </div>


        </div> : ''}
    </section>
  )
}

export default BlogPostRichText